export default function formatUrl(url) {
  let formattedUrl;
  const prefix = 'https://';
  if (url.substr(0, prefix.length) !== prefix) {
    formattedUrl = prefix + url;
  } else {
    return url;
  }
  return formattedUrl;
}
