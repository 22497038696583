/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import Image from '../components/Image';
import Layout from '../components/Layout';
import { getMembersByID, updateMembers } from '../redux/members';
import formatUrl from '../lib/formatUrl';
import handleGroupImagesUploadcare from '../lib/handleGroupImagesUploadcare';

const MainContainer = styled.div``;

const InfoContainer = styled.div`
  border-top: 1px solid #0f0f3a;
  border-bottom: 1px solid #0f0f3a;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const FeatureImageContainer = styled.div`
  flex-grow: 1;
  margin: 20px 0px;
  @media only screen and (max-width: 800px) {
  }
`;

const MainInfoContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
  margin-left: 10px;
`;

const DateContainer = styled.div`
  font-weight: normal;
  font-size: 20px;
  display: flex;
  color: #000000;
  flex-grow: 1;
`;

const NameAndAddress = styled.div`
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  @media only screen and (max-width: 800px) {
    margin-top: 20px;
  }
`;

const NameContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-weight: 600;
  font-size: 20px;
  color: #0f0f3a;
`;

const AddressContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-weight: normal;
  font-size: 20px;
  color: #0f0f3a;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const DetailsContainer = styled.div`
  margin-top: 12px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  font-size: 16px;
  color: #0f0f3a;
  flex-wrap: wrap;
  line-height: 40px;
`;

const Phone = styled.div``;

const Email = styled.div`
  text-decoration: underline;
`;
const Website = styled.div``;

const MainBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  border-bottom: 1px solid #0f0f3a;
  margin: 32px 0px;
`;

const AboutTitle = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-weight: 600;
  font-size: 20px;
  color: #0f0f3a;
  line-height: 40px;
  p {
    font-weight: normal;
    text-indent: 8px;
  }
  p::first-letter {
    text-transform: uppercase;
}
`;

const Description = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 32px 0px;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
`;
const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 40px;
  justify-content: space-between;
`;
const ImageBox = styled.div`
  background-color: #c4c4c4;
  height: 350px;
  width: 30%;
  @media only screen and (max-width: 800px) {
    width: 100%;
    margin-top: 20px;
  }
`;

const MembersPage = (props) => {
  const memberId = props['*'];
  const dispatch = useDispatch();
  const member = useSelector(getMembersByID(memberId));
  const selectedMember = member[0];
  const [viewCountUpdated, setViewCountUpdated] = useState(false);

  useEffect(() => {
    let viewCount = selectedMember?.viewCount;
    if (!viewCountUpdated && viewCount !== undefined) {
      if (viewCount == null) viewCount = 1;
      else viewCount += 1;
      const data = {
        viewCount,
      };
      dispatch(updateMembers({ id: memberId, data, action: 'updateViewCount' }));
      setViewCountUpdated(true);
    }
  }, [selectedMember]);

  if (member.length !== 1) {
    return (
      <Layout title="Member Not Found" subtitle="">
        <div>Not Found</div>
      </Layout>
    );
  }

  const {
    companyName = '',
    companyEmail = '',
    companySpecialities = '',
    companyWebsite = '',
    fullName = '',
    companyDescription = '',
    images = ['default_membership_logo.png'],
    companyAddress1 = '',
    companyCity = '',
    companyState = '',
    companyZip = '',
    companyPhone = '',
  } = member[0];
  let urlappend = `${companyAddress1} ${companyCity} ${companyState} ${companyZip}`;

  urlappend = encodeURIComponent(urlappend);
  const allImages = handleGroupImagesUploadcare(images);

  return (
    <Layout title={companyName} subtitle="">
      <MainContainer>
        <InfoContainer>
          {(images.length > 1) && (
          <FeatureImageContainer>
            <Image fileName={allImages[0]} width="300px" />
          </FeatureImageContainer>
          )}

          <MainInfoContainer>
            <DateContainer> Verified Member</DateContainer>
            <NameAndAddress>
              {companyName && (
              <NameContainer>
                <a href={`https://www.google.com/maps/place/${urlappend}`}>
                  {companyName}
                </a>
              </NameContainer>
              )}
              {companyAddress1 && (
              <AddressContainer>
                <a href={`https://www.google.com/maps/place/${urlappend}`}>
                  {companyAddress1}
                </a>
              </AddressContainer>
              )}
              {(Boolean(companyCity) || Boolean(companyState) || Boolean(companyZip)) && (
              <AddressContainer>
                <a href={`https://www.google.com/maps/place/${urlappend}`}>
                  {companyCity && `${companyCity}, `}
                  {companyState && `${companyState}, `}
                  {companyZip && `${companyZip}`}
                </a>
                <br />
              </AddressContainer>
              )}
            </NameAndAddress>
          </MainInfoContainer>
          {(companyPhone || companyEmail || companyWebsite) && (
          <DetailsContainer>
            {companyPhone && <Phone><a href={`tel: ${companyPhone}`}>{companyPhone}</a></Phone>}
            {companyEmail && (
            <Email>
              <a href={`mailto: ${companyEmail}`}>{companyEmail}</a>
            </Email>
            )}
            { companyWebsite && (
            <Website>
              <a href={formatUrl(companyWebsite)} target="_blank" rel="noreferrer">{companyWebsite}</a>
            </Website>
            )}
          </DetailsContainer>
          )}
        </InfoContainer>
        <MainBodyContainer>
          {companySpecialities && (
          <AboutTitle>
            Specialities:
            {' '}
            <p>
              {companySpecialities && companySpecialities.split(',').map((item) => {
                const labelHumanReadable = item?.split('_').join(' ');
                return (
                  <span>
                    {labelHumanReadable}

                    {
                  /* TODO Fix the root cause of this as this is a hack to get it working */
                  labelHumanReadable ? ', ' : ''
                  }
                  </span>
                );
              })}
            </p>
          </AboutTitle>
          )}
          {fullName && (
          <AboutTitle>
            Contact:
            {' '}
            <p>{`${fullName}`}</p>
          </AboutTitle>
          )}
          {companyDescription && (
          <Description>
            <p>{companyDescription}</p>
          </Description>
          )}
        </MainBodyContainer>
        <ImageContainer>

          {allImages.length > 1 && <Image fileName={allImages[1]} width="300px" />}
          {allImages.length > 2 && <Image fileName={allImages[2]} width="300px" />}
          {allImages.length > 3 && <Image fileName={allImages[3]} width="300px" />}
        </ImageContainer>
      </MainContainer>
    </Layout>
  );
};

export default MembersPage;
