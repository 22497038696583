function processGroupImage(acc, image) {
  const uploadCareLinkSplit = image.split('/');
  if (uploadCareLinkSplit.length < 5) {
    return acc.push(image);
  }
  const imageId = uploadCareLinkSplit[uploadCareLinkSplit.length - 2];
  const imageLinkAndNumberOfImages = imageId.split('~');
  for (let i = 0; i < imageLinkAndNumberOfImages[1]; i++) {
    acc.push(`https://ucarecdn.com/${imageId}/nth/${i}/`);
  }
  return acc;
}

function handleUploadCareGroupImages(images = []) {
  if (images[0] === 'default_membership_logo.png') {
    return images;
  }
  const allImages = images.reduce(processGroupImage, []);
  return allImages;
}

export default handleUploadCareGroupImages;
